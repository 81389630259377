<template>
  <div class="products">
    <div class="three-btns container mb-4">
      <button @click="$router.push('/')">{{ $t("Main") }} /</button>
      <button>{{ $t("Shops") }}</button>
    </div>

    <!-- categories and recently arrived -->
    <div class="categories mt-11 mt-5 px-4">
      <div class="new m-2">
        <div class="mb-3">
          <div class="m-5 text-center" v-if="list.length == 0">
            <img
              src="../../assets/images/notFoundIllustration.svg"
              class="mt-5"
              alt=""
              style="max-width: 300px"
            />
          </div>
          <div class="row g-4" v-if="list.length > 0">
            <div class="col-6 col-md-4 col-lg-3" v-for="(item, index) of list" :key="index">
              <div
                @click="$router.push('/shops/' + item._id)"
                style="cursor: pointer"
                class="border p-2 rounded h-100 d-block"
              >
                <img
                  :src="$baseUploadURL + item.vendorImage"
                  :alt="item.name"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- <Paginator  :pageNumber="pageNum"
          v-if="list.length > 0"
          style="direction: ltr"
          :rows="limit"
          :totalRecords="totalItemsCount"
          @page="onPage($event)"
        ></Paginator> -->
        <infinite-loading @infinite="loadMore" :identifier="pageNum">
              <div slot="no-more">{{ $t('No more data') }}</div>
              <div slot="no-results">{{ $t('No results') }}</div>
            </infinite-loading>


      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: { InfiniteLoading },
  head: {
    title: function () {
      return {
        inner: this.$getByLang(this.one.name),
      }

    },
  },
  data() {
    return {

      pageNum:
        sessionStorage.homeHerePageAllshops == "allshops"
          ? parseInt(sessionStorage.homeHerePageNumberAllShops)
          : 1,
      body: {
        categoriesId: [],
        subCategoriesId: [],
        sizes: [],
        colorsList: {
          colorId: null,
        },
        sizes: {
          sizeId: [],
        },
        price: [15000, 77000],
        vendorsId: [],
      },
      categoriesList: [],
      subcategoriesList: [],
      sizeList: [],
      colorsList: [],
      materialsList: [],
      list: [],
      totalItemsCount: 0,

      limit: 0,
      page: 1,
      id: null,
      one: {
        categoriesId: {
          name: {
            ar: null,
            en: null,
          },
        },
        name: {
          ar: null,
          en: null,
        },
        bannerSubCategoryImage: null,
      },
    };
  },
  methods: {
     $goToSave(link) {
      sessionStorage.setItem("homeHerePageAllshops", "allshops");
      sessionStorage.setItem("homeHerePageNumberAllShops", this.pageNum);
      this.$router.push(link);
    },
    onPage(event) {
      this.pageNum = event.page + 1;
      this.getData(event.page + 1);
      sessionStorage.setItem("homeHerePageAllshops", "allshops");
      sessionStorage.setItem("homeHerePageNumberAllShops", this.pageNum);
    },
    async loadMore($state) {
        if (this.allDataLoaded) {
            $state.complete();
            return;
        }

        try {
            const res = await this.getData2(this.pageNum);

            if (res.length) {
                this.list = [...this.list, ...res];
                this.pageNum += 1; // زيادة رقم الصفحة
                $state.loaded();
            } else {
                this.allDataLoaded = true; // تعيين علامة التوقف عند تحميل كل البيانات
                $state.complete();
            }
        } catch (error) {
            console.error("Error loading more data:", error);
            $state.complete();
        }
    },
    async getData2(page = 1) {

   

      const res = await this.$http.post(`vendors/search?limit=12&page=${page}`, obj);
      return res.data.docs.filter(li=>li.showInDetails == true);
    },
    getData(page = 1) {
      this.$http
        .post(`vendors/search?limit=12&page=${page}`, {
          vendorsId: this.id,
        })
        .then((res) => {
          this.list = res.data.docs.filter(li=>li.showInDetails == true);
          this.totalItemsCount = res.data.totalDocs;
          this.limit = res.data.limit;
        });
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.$http.get("vendors/" + this.id).then((res) => {
      this.one = res.data;

      this.$emit('updateHead')
    });

    this.getData();
  },
  watch: {},
};
</script>
